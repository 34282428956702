import { template as template_06bf06692d974075a5e1d211cfd1827d } from "@ember/template-compiler";
const WelcomeHeader = template_06bf06692d974075a5e1d211cfd1827d(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
