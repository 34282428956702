import { template as template_75003b569df64190b4f9579186996953 } from "@ember/template-compiler";
const SidebarSectionMessage = template_75003b569df64190b4f9579186996953(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
